
.access-to-calendar {
  .rs-picker-date {
    margin-bottom: 1rem;
  }
}
.shared-vehicles-list {
  list-style: none;
  padding: 1rem;
  .loader-container {
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    opacity: 0;
    visibility: hidden;
  }
  &.loading {
    // position: relative;
    .loader-container {
      opacity: 0;
      visibility: hidden;
    }
  }
  .shared-header {
    display: flex;
    flex-direction: row;
    .shared-title {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
    }
  }
  li.shared-vehicles-position {
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0 0 0;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    .row-first {
      display: flex;
      flex-direction: row;
      .name-date {
        flex: 1;
        .date-margin {
          margin-left: 0.5rem;
        }
        .name {
          font-weight: 600;
        }
      }
      .copy-link {
        margin-right: 0.5rem;
      }
      .edit-vehicle-access,
      .copy-link {
        height: 14px;
        cursor: pointer;
        svg {
          width: 14px;
          height: 14px;
          display: block;
          fill: $color--primary;
        }
      }
    }
    .row-second {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .share-delete {
        height: 14px;
        margin-bottom: 0.5rem;
        cursor: pointer;
        svg {
          width: 14px;
          height: 14px;
          display: block;
          fill: #e74c3c;
        }
      }
    }
  }
  .shared-vehicles-list-assigned-vehicles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-top: 0.5rem;
    flex: 1;

    .assign-vehicle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;

      .add-vehicle {
        height: 14px;
        cursor: pointer;
        svg {
          width: 14px;
          height: 14px;
          display: block;
          fill: $color--primary;
        }
      }
    }

    .assigned-vehicle {
      background: #5573df;
      color: #fff;
      padding: 0.25rem 0.25rem 0.18rem;
      font-size: 0.65rem;
      border-radius: 4px;
      text-align: center;
      min-width: 1.75rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .reg {
        margin-right: 0.5;
      }
      .remove-vehicle {
        height: 14px;
        cursor: pointer;
        svg {
          width: 14px;
          height: 14px;
          display: block;
        }
      }
    }
  }
}

.vehile-access-page {
  .content-left {
    flex-grow: 0;
    min-width: 400px;
    max-width: 450px;
    width: 33.333333%;
    .header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ccc;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-bottom: 0.5rem;
      .name,
      .valid-untill {
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        .label {
          min-width: 6rem;
        }
      }
      .name {
        font-weight: 600;
      }
    }
  }
  .content-right {
    flex: 1;
    width:100%;
  }
  &.loggedout {
    height: 100vh;
    .panel.left {
        max-height: 100vh;
      }
    .leaflet-container {
      height: 100vh;
    }
  }
}

.form-add-shared-vehiles {
  label {
    position: relative;
    display: block;
    margin-bottom: 2rem;
    .validation-error {
      font-size: 0.75rem;
      position: absolute;
      color: #e74c3c;
    }
    &.name {
      .validation-error {
        bottom: -1rem;
      }
    }
    &.access-to-calendar {
      .validation-error {
        bottom: 0;
      }
    }
  }

}

.plusgps_styles {
  .shared-vehicles-list {
    li.shared-vehicles-position {
      .row-first {
        .edit-vehicle-access,
        .copy-link {
          svg {
            fill: $color--primary-plusgps;
          }
        }
      }
    }
    .shared-vehicles-list-assigned-vehicles {
      .assign-vehicle {
        .add-vehicle {
          svg {
            fill: $color--primary-plusgps;
          }
        }
      }

      .assigned-vehicle {
        background: $color--primary-plusgps-light;
      }
    }
  }
}
