.driver-assist {
    flex-direction: column;
    padding: 0.5rem;
    max-width: 100%;
    .text-container {
        border: 1px solid #eee;
        padding: 0.5rem;
    }
    .camera-container {
        padding: 0.5rem;
    }
}