.spinner {

}

.lds-dual-ring {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0;
  border-radius: 50%;
  border: 2px solid $color--primary;
  border-color: $color--primary transparent $color--primary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
