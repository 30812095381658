.rs-loader-spin::before {
  border: 3px solid $color--primary-matching50;
}
.rs-loader-spin::after {
  border-color: $color--primary transparent transparent;
}

.plusgps_styles {
  .rs-loader-spin::before {
    border: 3px solid $color--primary-plusgps-light-matching50;
  }
  .rs-loader-spin::after {
    border-color: $color--primary-plusgps-light transparent transparent;
  }
}
