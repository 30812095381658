.content-wrapper.charts {
  flex-direction: column;
  .content-head {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    .selected-vehicle,
    .selected-parameters {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0.5rem;
    }
    .selected-parameters {
      .full-width {
        max-width: 12rem;
      }
    }
    .selected-vehicle,
    .selected-parameters,
    .visible-date {
      .label {
        margin-right: 0.5rem;
      }
    }
    @media screen and (max-width: 782px) {
      flex-direction: column;
      .selected-vehicle,
      .selected-parameters {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
  .content-left {
    flex-grow: 1;
    padding: 2rem;
    @media screen and (max-width: 782px) {
    margin-right: 0;
    padding: 1rem;
    }
  }
}

.custom-tooltip {
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 0.75rem;
  .date-time {
    font-weight: 600;
    color: #5b6366;
  }
  .par {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 0.25rem 0;
    .color-dot {
      height: 0.45rem;
      width: 0.45rem;
      margin-right:0.25rem;
      border-radius: 0.25rem;
    }
    .label {
      min-width: 3rem;
      margin-right:0.25rem;
      flex-grow: 1;
    }
  }
}

.vehicle-picker {
    min-width: 12rem;
}

.params-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $color__border-element;
    .param-id {
        width: 4rem;
    }
    .param-date {
        width: 12rem;
    }
    .param-satelites {
        width: 3rem;
    }
    .param-voltage {
        width: 6rem;
    }
    .param-odometer {
        width: 12rem;
    }
    .param-total-fuel-used {
        width: 8rem;
    }
}


.loader-container {
    opacity: 0;
    visibility: hidden;
    min-height: 0;
    display: none;
    align-items: center;
    justify-content: center;
    &.loading {
      display: flex;
      min-height: 6rem;
      opacity: 1;
      visibility: visible;
    }
  }