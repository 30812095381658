.notifications,
.notifications-rules {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;

  .header {
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    .left {
      flex-grow: 1;
      .header-list {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 0 2rem;
        li {
          padding: 1rem;
          border-bottom: 2px solid #ccc;
          a {
            text-decoration: none;
          }
          &.active {
            border-bottom: 2px solid $color--primary;
            font-weight: 600;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 1rem;
    }

  }
  .content {
    display: flex;
    flex-direction: row;
    .content-left {
      flex-grow: 1;
      padding: 0.5rem 2rem 2rem 2rem;

      &.opened {
        // min-width: 300px;
        margin-right: 33.333333%;
      }
    }
    .content-right {
      width: 33.333333%;
      position: absolute;
      right: -33.333333%;
      @media screen and (max-width: 782px) {
        flex-direction: column;
        width: 100%;
        right: -100%;
      }
      &.opened {
        right: 0;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li.notification {
        display: flex;
        flex-direction: row;
        font-size: 0.875rem;
        border-bottom: 1px solid #ccc;
        .left {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
        .right{
          display: flex;
          flex-direction: row;
          align-items: center;
          .switch,
          .read {
            margin-right: 1rem;
          }
          .read,
          .details {
            cursor: pointer;
          }
          .read.true {
            cursor: inherit;
          }
        }
        .label {
          margin-right: 0.5rem;
        }
        .top-line {
          font-size: 1rem;
          font-weight: 600;
        }
        .middle-line {
          .vehicle {
            display: flex;
            flex-direction: row;
            align-items: center;
            .value {
              display: flex;
              flex-direction: row;
              border: 1px solid #eee;
              padding: 0.25rem 0.5rem;
              .slash {
                margin: 0 0.5rem;
              }
            }
          }
          .rule {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        .bottom-line {
          display: flex;
          flex-direction: row;
          .date-start,
          .date-end,
          .duration {
            display: flex;
            flex-direction: row;
          }
          .date-end,
          .date-start {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .list-element-details {
    .map-framer {
      width: 100%;
      height: 260px;
      .leaflet-container {
        width: 100%;
        height: 260px;
      }
    }
  }
  .notification-details {
    padding: 1rem;
  }
  .filters {
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0.5rem 0;
    @media screen and (max-width: 782px) {
      flex-direction: column;
    }
    .rs-picker-select {
      margin-right: 1rem;
    }
    .visible-date {
      margin-right: 0rem;
    }
    .filters-button {
      margin-left: 1rem;
    }
  }
}

.notifications-icon {
  height: 4rem;
  position: relative;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  .counter {
    position: absolute;
    top: 0.65rem;
    right: 0.65rem;
    border-radius: 0.25rem;
    min-width:1rem;
    padding: 0.125rem;
    height:1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
    background: #e74c3c;
    font-size: 0.875rem;
    visibility: hidden;
    opacity: 0;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}

.notifications-latest-list {
  border-radius: 0.5rem;
  position: absolute;
  top: 5rem;
  box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.12);
  right: 4rem;
  width: 40rem;
  max-height: 30rem;
  z-index: 400000;
  overflow-y: auto;
  // border: 1px solid #000;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  padding: 1rem;
  &.opened {
    visibility: visible;
    opacity: 1;
  }
  ul {
    margin: 0;
    padding: 0;
    li.no-notifications {
      list-style: none;
    }
    li.notification {
      display: flex;
      flex-direction: row;
      font-size: 0.875rem;
      border-bottom: 1px solid #ccc;
      @media screen and (max-width: 782px) {
        flex-direction: column;
      }
      .left {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      .right{
        display: flex;
        flex-direction: row;
        align-items: center;
        .switch,
        .read {
          margin-right: 1rem;
        }
        .read,
        .details {
          cursor: pointer;
        }
        .read.true {
          cursor: inherit;
        }
      }
      .label {
        margin-right: 0.5rem;
      }
      .top-line {
        font-size: 1rem;
        font-weight: 600;
      }
      .middle-line {
        .vehicle {
          display: flex;
          flex-direction: row;
          align-items: center;
          @media screen and (max-width: 782px) {
            flex-direction: column;
          }
          .value {
            display: flex;
            flex-direction: row;
            border: 1px solid #eee;
            padding: 0.25rem 0.5rem;
            .slash {
              margin: 0 0.5rem;
            }
          }
        }
        .rule {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .bottom-line {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 782px) {
          flex-direction: column;
        }
        .date-start,
        .date-end,
        .duration {
          display: flex;
          flex-direction: row;
        }
        .date-end,
        .date-start {
          margin-right: 1rem;
        }
      }
    }
  }
  .bottom-line {
    padding-top: 0.5rem;
  }
}


.form-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1rem;
  .delete-button {
    margin-right: 0.5rem;
  }
}
