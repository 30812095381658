$color--light-gray: #f5f5f5;
$color--medium-gray: #c9cacc;
$color__border-input: #ccc;
$color__border-element: #ccc;
$color--primary: #5573df;
$color--primary-matching50: rgba(128,151,255,0.5);
$color--primary-matching70: rgba(128,151,255,0.7);
$color--primary-matching100: #8097FF;
$color--primary-plusgps: #35459c;
$color--primary-plusgps-light-matching50: rgba(78,174,236,0.5); // 4EAEEC
$color--primary-plusgps-light-matching70: rgba(78,174,236,0.7); // 4EAEEC
$color--primary-plusgps-light-matching100: rgba(78,174,236,1); // 4EAEEC
$color--primary-plusgps-light: #46c7ef;
$color--button: #000;
$color--text: #535353;
$color--text-dark: #242424;
$color--marker-gray: #b2b2b2;
$color--green: #6ab04c;
$color--red: #e74c3c;

