.content-wrapper.integrations {
    ul {
      li {
        padding: 1rem 0;
        .vehicle-container {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .dot {
          width: 1rem;
          height: 1rem;
          border-radius: 1rem;
          margin-right: 1.5rem;
          &.dot-green {
            background: $color--green;
            box-shadow: 0px 0px 0px 2px #fff inset;
            border: 2px solid $color--green;
          }
          &.dot-red {
            background: $color--red;
            box-shadow: 0px 0px 0px 2px #fff inset;
            border: 2px solid $color--red;
          }
        }
        .vehicle {
          min-width: 10rem;
          .codename {
            margin-right: 1rem;
          }
        }
        .vehicle,
        .date-start,
        .parameters {
          display: flex;
          flex-direction: row;
          margin-right: 1.5rem;
        }
        .date-start {
          min-width: 12rem;
        }
        .parameters {
          .par {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid $color__border-element;
            border-radius: 0.25rem;
            margin-right: 0.5rem;
            padding: 0 0.35rem;
            &.ignition,
            &.movement {
              min-width: 4rem;
            }
            &.satelites {
              min-width: 3rem;
            }
            &.voltage {
              min-width: 5rem;
            }
            .label {
              height: 1em;
              width: 1em;
              margin-right: 0.35rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  