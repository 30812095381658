// #panel-left-map {
//   display: none;
// }

.mycluster {
  width: 40px;
  height: 40px;
  background-color: greenyellow;
  text-align: center;
  font-size: 24px;
}

.rs-picker-toggle-wrapper {
    width: 100%;
}


.custom-marker-cluster{
  background: rgba(85,115,223,0.9);
  border: 3px solid rgba(255,255,255,0.3);
  box-shadow: 0 0 0 3px rgba(85,115,223,0.2);
  border-radius: 50%;
  color: #fff;
  height: 33px;
  line-height: 27px;
  font-weight: 600;
  text-align: center;
  width: 33px;
}

.total_tracks_distance {
  &.value {
    margin-bottom: 0.5rem;
  }
}




.map-type-control {
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  svg {
    width: 24px;
    height: 24px;
    fill: rgba(87,87,87,1);
  }
  &.satelite {
    svg {
      fill: $color--primary;
    }
  }
}





.content-wrapper.vehile-access-page,
.content-wrapper.map {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 782px) {
    width: 100%;
    position: relative;
  }
  .panel.left {
      min-width: 400px;
      max-width: 600px;
      width: 33.333333%;
      padding: 1rem;
      max-height: calc(100vh - 4rem);
      overflow-y: scroll;
      @media screen and (max-width: 782px) {
        min-width: auto;
        max-width: auto;
        width: 100%;
        min-height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        // z-index: 120;
        z-index: 424;
        background: #fff;
        &.opened {
          // z-index: 122;
          z-index: 426;

        }
      }
      ul.vehicles-list {
        list-style: none;
        padding: 0;
        // @media screen and (max-width: 782px) {
        //   margin-top: 2.5rem;
        // }
        li.vehicle {
          margin-bottom: 0.5rem;
          border-bottom: 1px solid #ccc;
          padding: 0.5rem;
          display: flex;
          flex-direction: row;
          width: 100%;
          .left{
            flex-grow: 1;
          }
          .licenseplate {
            font-size: 1rem;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            align-items: center;
            .country-short {
              background: $color--primary;
              color: #fff;
              padding: 0.15rem 0.2rem 0.08rem;
              font-size: 0.65rem;
              border-radius: 4px;
              text-align: center;
              min-width: 1.75rem;
              margin-left: 0.5rem;
            }
            .country-short-disabled {
              display: none;
            }
          }
          .middle-line {
            margin:0;
            padding:0;
            list-style:none;
            display: flex;
            flex-direction: row;
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
            & > li {
              flex: 25%;
              span.label {
                margin-right: 0.25rem;
                position: relative;
                top: 2px;
                color: $color--primary;
              }
              span.unit {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
  }
  .panel.details {
    background: #fff;
    overflow: hidden;
    width: 0;
    transition: width .1s ease-in-out;
    @media screen and (max-width: 782px) {
      min-width: auto;
      max-width: auto;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      // z-index: 120;
      z-index: 424;
    }
    &.opened {
      // min-width: 300px;
      overflow: visible;
      min-width: 400px;
      max-width: 600px;;
      width: 33.333333%;
      height: calc(100vh - 4rem);
      overflow: hidden;
      @media screen and (max-width: 782px) {
        // min-width: auto;
        // max-width: auto;
        // width: 100%;
        // position: absolute;
        // left: 0;
        // top: 0;
        // z-index: 120005;
        min-width: auto;
        max-width: 100%;
        height: calc(100% - 4rem);
        width: calc(100% - 4rem);
        /* max-height: 100%; */
        /* min-height: 100%; */
        position: fixed;
        left: 4rem;
        top: 4rem;
        // z-index: 125;
        z-index: 426;
        display: flex;
        opacity: 1;
        visibility: visible;
      }
    }
    &.mobile-map-view {
      opacity: 0;
      visibility: hidden;
    }
    & > .inner {
      border-left:1px solid $color__border-element;
      display: flex;
      // min-width: 400px;
      // max-width: 600px;
      width: calc(100%);
      // height: calc(100%)
      height: calc(100vh - 4rem);
      // padding: 1.5rem;
      & > div {
        min-width:120px;
        width: 100%;
      }
      .panel-header {
        display: flex;
        flex-direction:row;
        align-items: center;
        border-bottom: 1px solid $color__border-element;
        background: #fafafa;
        // padding: 1.5rem 1.5rem 1rem;
        padding: 1em 1rem 0.75rem;

        @media screen and (max-width: 782px) {
          padding: 1em 1rem 0.75rem;
        }
        .name {
          flex-grow: 1;
          .licenseplate {
            font-size: 1.25rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  .map {
    width: 100vh;
    background: #eee;
    flex-grow: 1;
  }
}

.leaflet-container {
  width: 100%;
  height: calc(100vh - 4rem);

  @media screen and (max-width: 782px) {
    min-width: auto;
    max-width: auto;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    // z-index: 121;
    z-index: 425;
  }
}

.marker-vehicle {
  display: flex;
  flex-direction: row;
  height: auto !important;
  & > .label {
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 0.25rem;
    padding: 0.35rem;
    display: flex;
    flex-direction: column;
    position: relative;
    top:-20px;
    left:-4px;
    min-height: auto;
    & > .more {
      // border: 1px solid #eee;
    }
    & > .less {
      // border: 1px solid #eee;
      display: flex;
      flex-direction: row;
      & > .direction_icon {
        margin-right: 0.25rem;
        & > svg {
          position: relative;
          top: 1px;
          path {
            fill: $color--primary;
          }
        }
        & > .parking-mode {
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          color: $color--primary;
          line-height: 1;
        }
      }
      & > .text {
        color: $color--text;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        line-height: 1;
        // top: -2px;
      }
    }
  }
  .icon {
    border: 3px solid rgba(85,115,223,0.7);
    background: rgba(255,255,255,0.5);
    width:16px;
    height:16px;
    border-radius: 50% 50% 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top:-10px;
    left:-10px;
    & > .inner {
      width:8px;
      height:8px;
      border-radius: 4px;
      background: $color--primary;

    }
  }
}

.marker-vehicle.open {
  // background: red;
}

.marker-track {
  display: flex;
  flex-direction: row;
  & > .label {
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 0.25rem;
    padding: 0.35rem;
    display: flex;
    flex-direction: row;
    position: relative;
    top:-20px;
    left:-4px;
    & > .direction_icon {
      // margin-right: 0.25rem;
      & > svg {
        position: relative;
        top: 1px;
        path {
          fill: $color--primary;
        }
      }
      & > .parking-mode {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: $color--primary;
        line-height: 1;
      }
    }
    & > .text {
      color: $color--text;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      position: relative;
      line-height: 1;
      // top: -2px;
    }

  }
  .icon {
    border: 3px solid rgba(147,147,147,0.7);
    background: rgba(255,255,255,0.5);
    width:16px;
    height:16px;
    border-radius: 50% 50% 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top:-10px;
    left:-10px;
    & > .inner {
      width:8px;
      height:8px;
      border-radius: 4px;
      background: $color--marker-gray;
    }
  }
}

.leaflet-popup-content-wrapper {
    border-radius: 4px;
}
.leaflet-popup-content {
    margin: 16x 16px;
}

.leaflet-popup-tip {
    width: 8px;
    height: 8px;
    margin: -5px auto 0;
  }

.rdrStaticRanges {
  & > button {
    color: #1d2429;
  }
}

.cst-range-picker-container {
  width: 100%;
}
.cst-range-picker {
  width: 100%;
  @media screen and (max-width: 782px) {
    max-width: 13.9rem;
  }
}

.track-selector {
  display: flex;
  flex-direction: row;
}

.visible-date {
  // border: 1px solid $color__border-element;
  display: flex;
  flex-direction: row;
  // padding: 0.25rem;
  width: 100%;
  align-items: center;
  margin-right: 1rem;
  @media screen and (max-width: 782px) {
    margin-right: 0.5rem;
  }
  .visible-date-start {
    &:after {
      margin-left: 0.5rem;
      content: '-';
      margin-right: 0.5rem;
    }
  }
  .visible-date-end {

  }
  .calendar-icon {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 0.5rem;
  }
}

// .cst-range-picker {
//   box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.12);
// 	position: absolute;
// 	// top: 0;
// 	// right: 0;
// 	z-index: 1000;
//   visibility: hidden;
//   opacity: 0;
//   &.opened {
//     visibility: visible;
//     opacity: 1;
//   }
// }

.cst-device-tracks {
  padding: 1rem;
  // border: 1px solid red;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.info-container {
  padding: 1rem;
  ul.elements-list {
    list-style: none;
    margin: 0;
    padding: 0;
    &.margin-bottom {
      margin-bottom: 0.75rem;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid $color__border-element;
    }
    li {
      margin: 0 0 0.5rem 0;
      display: flex;
      flex-direction: row;
      span.label{
        flex-grow: 1;
      }
    }
  }
}

.react-tabs__tab-panel--selected {
    /* border: 1px solid blue; */
    display: flex;
    max-height: calc(100vh - 197px);
    overflow-y: auto;
    // overflow-y: scroll;
}

.tracks-list-container {
  padding-top: 0.5rem;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // border: 1px solid green;
  .tracks-loader-container {
    opacity: 0;
    visibility: hidden;
    min-height: 0;
    display: none;
    align-items: center;
    justify-content: center;
    &.loading {
      display: flex;
      min-height: 6rem;
      opacity: 1;
      visibility: visible;
    }
  }
  .track-container {
    border-top: 1px solid $color__border-element;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding: 0.5rem;
    display:flex;
    flex-direction: row;
    &:first-child {
      border-top: none;
    }
    .left {
      flex-grow: 1;
    }
    .right {
      .button-track-show {
        cursor:pointer;
        svg {
          fill: $color--text;
        }
      }
    }
    &.active {
      // background: rgba(85,115,223,0.07);
      border-left: 6px solid $color--primary;
      .right {
        .button-track-show {
          svg {
            fill: $color--primary;
          }
        }
      }
    }
  }
}


.poly-active {
  z-indeX: 900 !important;
  fill: red;
  color: red;
  opacity: 1;
}


// .top-bar-elements {
  .map-switch {
    display: none;
    @media screen and (max-width: 782px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $color--primary;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      // z-index: 124;
      z-index: 426;
      position: fixed;
      top: calc(100% - 3.5rem);
      right: 0.5rem;
      svg {
        fill: #fff;
      }
    }
  }
// }








.water-flow{
  stroke: lime;
  stroke-width: 3;
  animation: 3s ease infinite Anim;
}

@keyframes Anim {
  0%{
    stroke : blue;
  }

  2%{
    stroke: green;
  }

  50%{
    stroke: red;
  }

  98%{
    stroke: cyan;
  }

  100%{
    stroke: yellow;
  }
}




.container.plusgps_styles {
  .custom-marker-cluster{
    background: rgba(70,199,239,0.9);
    border: 3px solid rgba(255,255,255,0.3);
    box-shadow: 0 0 0 3px rgba(50,199,239,0.2);
  }
  .content-wrapper.map {
    .panel.left {
      ul.vehicles-list {
        li.vehicle {
          .licenseplate {
            .country-short {
              background: $color--primary-plusgps-light;
            }
          }
          .middle-line {
            & > li {
              span.label {
                color: $color--primary-plusgps;
              }
            }
          }
        }
      }
    }
  }
  .marker-vehicle {
    & > .label {
      & > .less {
        & > .direction_icon {
          & > svg {
            path {
              fill: $color--primary-plusgps;
            }
          }
          & > .parking-mode {
            color: $color--primary-plusgps;
          }
        }
      }
    }
    .icon {
      // border: 3px solid rgba(40,124,450,0.7);
      border: 3px solid rgba(70,199,239,0.7);

      & > .inner {
        background: $color--primary-plusgps-light;
      }
    }
  }
  .marker-track {
    display: flex;
    flex-direction: row;
    & > .label {
      & > .direction_icon {
        & > svg {
          path {
            fill: $color--primary-plusgps;
          }
        }
        & > .parking-mode {
          color: $color--primary-plusgps;
        }
      }
    }
  }
  .tracks-list-container {
    .track-container {
      &.active {
        border-left: 6px solid $color--primary-plusgps;
        .right {
          .button-track-show {
            svg {
              fill: $color--primary-plusgps;
            }
          }
        }
      }
    }
  }
  .map-switch {
    @media screen and (max-width: 782px) {
      background: $color--primary-plusgps;
    }
  }
}
