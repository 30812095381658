.login-placeholder {
  display: none;
}
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: $color--primary;

  .logo {
    width: auto;
    display: flex;
    justify-content: center;
    .st9 {
      fill: $color--primary;
    }
    .logo_plusgps {
      .logo_plusgps_02,
      .logo_plusgps_03 {
        fill: #46c7ef;
        opacity: 0.9;
      }
    }
    .logotype_plusgps {
      margin-left: 0.5rem;
      width: 6.5rem;
    }
  }

  .login-block {
    width: 100%;
    min-width: 400px;
    max-width: 540px;
    background: #fff;
    box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.12);
    padding: 1.5rem;
    @media screen and (max-width: 782px) {
      min-width: 320px;
    }
  }
}

.container.plusgps_styles {
  .login-container {
    background: $color--primary-plusgps-light;
    .login-block {
      form {
        display: flex;
        flex-direction: column;
        button {
          align-self: baseline;
        }
      }
    }
    .logo {
      align-self: center;
      width: 14rem;
      @media screen and (max-width: 782px) {
        width: 9rem;
      }
      .logo_cont {
        width: 100%;
      }
      svg {
        width: 100%;
      }
    }
    .logo {
      .st9 {
        fill: $color--primary-plusgps;
      }
    }
  }
}
