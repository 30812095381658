h1,
h2,
h3,
h4,
h5,
h6 {
  color: #5b6366;
}

p,
.text-color {
  color: #656b6e;
}

h1 {
  font-size: 1.75rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
@media screen and (max-width: 782px) {
  h1 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.15rem;
  }
}
