.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid $color__border-element;
    margin: 0 0 10px;
    padding: 0;
    position: relative;
    top:-1px;
    @media screen and (max-width: 782px) {
      display: flex;
    }
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem 1rem;
    cursor: pointer;

    &--selected {
      background: #fff;
      border-left: 1px solid $color__border-element;
      border-right: 1px solid $color__border-element;
      color: black;
    }

    &:first-child {
      // &--selected {
        border-left: none;
      // }
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   height: 5px;
      //   left: -4px;
      //   right: -4px;
      //   bottom: -5px;
      //   background: #fff;
      // }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
