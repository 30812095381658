.content-wrapper.orders {
  .content-left {
    min-width:  28rem;
    padding: 2rem;
    z-index: 425;
    background: #fff;
    .summary {
      border: 1px solid #ccc;
      padding: 1rem;
    }
  }
  .content-right {
    flex-grow: 1;
    padding: 0rem;
    position: relative;
  }
}
.autofill-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffd5d5;
  margin-bottom: 0.5rem;
  .handle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 0.5rem;
    height: 0.675rem;
    margin-right: 1rem;
    span {
      background: #a7a7a7;
      height: 0.175rem;
      width: 0.175rem;
    }
    span:nth-child(odd) {
      // background: red;
      margin: 0 0.15rem 0.15rem 0;
    }
  }
  .wrapper {
    flex: 1;
  }
  .delete {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}



.autocomplete-container {
  position: relative;
  input {
    margin-bottom: 0;
  }
  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 1rem;
    max-width: 69rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 404;
    opacity: 0;
    visibility: hidden;
    &.opened {
      opacity: 1;
      visibility: visible;
    }
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}
