@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap');



.hidden-options {
  display: none;
}

.logo_plusgps_01 {

}
.logo_plusgps_02,
.logo_plusgps_03 {
  fill: #fff;
  opacity: 0.9;
}


body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

.container {
  background: $color--primary;
}


.user_menu {
  .user-info {
    width: 4rem;
    height: 4rem;
  }
  .rs-dropdown-menu {
    z-index: 401;
    svg {
      width: 2rem;
    }
    .label {
      margin-left:0.5rem;
      a {
        color: var(--rs-text-primary);
        text-decoration: none;
      }
    }
  }
}

#root {
  min-height: 100vh;
}

.margin-bottom {
  margin-bottom: 0.5rem;
}

.container-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4rem;
  // padding-top: 1rem;
  transition: width .1s ease-in-out;
  z-index: 425;

  &.wide {
    width: 16rem;
  }
}

.logo {
  background: rgba(255, 255, 255, 0.11);
  // border-radius: 0.55rem;
  width:100%;
  height: 4rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
    svg{
      enable-background:new 0 0 153 159;
      width: 2rem;
      height: 2rem;
      padding-left: 0;
    }
  .st0{display:none;}
  .st1{display:inline;}
  .st2{fill:#18EAC5;}
  .st3{display:inline;fill:#FFFFFF;}
  .st4{display:inline;fill:#18EAC5;}
  .st5{display:inline;fill:#26EAC4;}
  .st6{display:inline;fill:#F0F7F7;}
  .st7{display:inline;fill:#5FEDD2;}
  .st8{display:inline;fill:#AD4040;}
  .st9{fill:#fff;}
  .st10{fill:#363638;}
}

nav.main-nav {
  display: flex;
  align-items: flex-start;

  padding: 1.5rem;
  flex-direction: column;
  transition: width .1s ease-in-out;
  min-width: 1%;
  flex: 1;
  .label {
    width: 0;
    overflow: hidden;
    display: inline-block;
    transition: width .1s ease-in-out;
    color: #fff;
    .inner {
        margin-left: 0.25rem;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      a {
        margin: 0.25rem 0;
        display: flex;
        min-width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 2rem;
        svg {
          width: 1.35rem;
          height: 1.35rem;
          color: $color--medium-gray;
        }
        &.active {
          background: rgba(255,255,255,0.11);
          svg {
            color: $color--light-gray;
          }
        }
      }
    }
  }
  &.wide {
    min-width: 220px;
    align-items: flex-start;
    .label {
      width: auto;
      overflow: hidden;
      display: inline-block;
    }
    ul {
      li {
        width: 100%;
        a {
          justify-content: flex-start;
          min-width: 100%;
          padding-left: 1rem;
        }
      }
    }
  }
}

.right-panel {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  background: #fff;
  flex-grow: 1;
  .top-bar {
    background: #fff;
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.11);
    z-index: 427;
    .left {
      display: flex;
      flex-grow: 1;
      .select-org {
        margin-left: 2rem;
        margin-right: 2rem;
        max-width: 10rem;
        display: flex;
        align-items: center;
        @media screen and (max-width: 782px) {
          max-width: 7rem;
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      .icon-element {
        width: 4rem;
        border-left: 1px solid $color__border-element;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.content-wrapper {
  background: #fff;
  // border-radius: 2rem 0 0 2rem;
  // padding: 2rem;
  height: calc(100vh - 4rem);
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  &.access-invalid {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .access-error {
      font-size: 2rem;
    }
    .date-valid-untill {
      margin-left: 0.5rem;
    }
  }
}



.page {
  display: flex;
  flex-direction: column;
  .devices {

  }
}


.nav-width-change {
  width: 100%;
}
.main-nav-width {
  width: 100%;
  height: 4rem;
  border-top: 1px solid rgba(255,255,255,0.1);
  .arrow {
    width: 100%;
    height: 4rem;
    display: inline-block;
    position: relative;
    margin: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: red;
    // display: none;
    & span {
      top: 0.5rem;
      position: absolute;
      height: 0.75rem;
      width: 0.1rem;
      background-color: #efefef;
      display: inline-block;
      transition: all 0.2s ease;
    }
    & span:first-of-type {
      top: 1.75rem;
      transform: rotate(45deg);
    }
    & span:last-of-type {
      top: 1.25rem;
      transform: rotate(-45deg);
    }
  }
  &.wide .arrow span:first-of-type {
    transform: rotate(-45deg);
  }
  &.wide .arrow span:last-of-type {
    transform: rotate(45deg);
  }
}



.preloader-show {
 .leaflet-container {
   opacity: 0;
   visibility: hidden;
 }
}
.preloader-hide {
  .leaflet-container {
    opacity: 1;
    visibility: visible;
  }
}

.auth-error {
  color: #fff;
}


.circle-initial {
  background: $color--primary;
  color: #fff;
  font-weight: 600;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .menu-el-temperature,
// .menu-el-adminpanel,
.send-order {
  display: none !important;

}

.icon-element {
  cursor: pointer;

  &.notifications {
    // display: none !important;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      min-width: 1.25rem;
      min-height: 1.25rem;
    }
  }

  &.user-info {
    position: relative;
    .user-menu {
      position: absolute;
      top: 100%;
      max-height: 0;
      overflow: hidden;
      background: #fff;
      padding: 0rem;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.11);
      right: 0rem;
      &.open {
      }

      ul {
        margin: 0;
        padding: 0.5rem;
        list-style: none;
        li {
          border-bottom: 1px solid $color__border-element;
          padding: 0.5rem  0.5rem;
          color: $color--text-dark;

          &:last-child {
            border-bottom: none;
            margin-bottom:0.25rem;
          }
          a {
            color: $color--text-dark;
            text-decoration: none;
          }
        }
      }
    }
    &.open {
      .user-menu {
        max-height: calc(100% + 1.25rem);
      }
    }
  }
}



.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
    width: 100%;
}

.rs-picker-menu {
  z-index: 440;
}

.container.plusgps_styles {
  nav.main-nav {
    .label {
      color: $color--primary-plusgps;
    }
    ul {
      li {
        svg {
          color: $color--primary-plusgps;
        }
      }
    }
  }



  background: $color--primary-plusgps;
  .container-left {
    background: #fff;
    border-right: 1px solid #ccc;
  }
  .top-bar {
      box-shadow: none;
      border-bottom: 1px solid #ccc;
  }

  .circle-initial {
    background: $color--primary-plusgps;
  }
}
