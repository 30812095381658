.list-element-details {
  // border: 1px solid magenta;
  width: 100%;
  .header {
    background: #fafafa;
    border-bottom: 1px solid $color__border-element;
    display: flex;
    flex-direction: row;

    .arrow-container {
      flex-grow: 0;
      cursor: pointer;
    }
    .name-container {
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .licenseplate,
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .edit-object {
    padding: 1rem;
    .info-container{
      padding: 0;
    }
    &.functions {
      .element {
          align-items: center;
          display: flex;
          flex-direction: row; 
          margin-bottom: 1rem;
          .label {
              flex: 1;
          }
      }
    }
  }

.arrow {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  position: relative;
  margin: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $color__border-element;
  // background: cyan;
  // display: none;
  & span {
    top: 0.65rem;
    position: absolute;
    height: 0.75rem;
    width: 0.1rem;
    background-color: $color__border-element;
    display: inline-block;
    transition: all 0.2s ease;
    margin-left: 0.25rem;
  }
  & span:first-of-type {
    top: 1.95rem;
    transform: rotate(-45deg);
  }
  & span:last-of-type {
    top: 1.45rem;
    transform: rotate(45deg);
  }
}
&.wide .arrow span:first-of-type {
  transform: rotate(-45deg);
}
&.wide .arrow span:last-of-type {
  transform: rotate(45deg);
}



}
