

.content-wrapper.dashboard {
  flex-grow: 1;
  padding: 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  background: rgba(0,0,0,0.05);

  @media screen and (max-width: 782px) {
    grid-template-columns: 100px;
    grid-template-rows: auto;
    display: flex;
    flex-direction: column;
  }
  .panel {
    border: 0px solid $color__border-element;
    border-radius: 0.5rem;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 0.0625rem 1.125rem 0 rgba(0,0,0,0.05), 0 0.1875rem 0.3125rem -0.0625rem rgba(0,0,0,0.07);
    // box-shadow: 1px 1px 18px 0px rgba(0, 0, 0, 0.16);
    .line {
      &.header{
        font-weight: 600;
        color: $color--text-dark;
        padding-bottom: 0.25rem;
        font-size: 0.875rem;
      }
      &.list {
        border-top: 1px solid $color__border-element;
        padding: 0.25rem 0;
        font-size: 0.875rem;
      }
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 782px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .licenseplate {
        width:20%;
        min-width: 116px;
        @media screen and (max-width: 782px) {
          width: auto;
          min-width: auto;
        }
      }
      .status {
        width:20%;
        min-width: 100px;
        @media screen and (max-width: 782px) {
          width: auto;
          min-width: auto;
        }
      }
      .speed {
        width:20%;
        min-width: 100px;
        flex-grow: 1;
        @media screen and (max-width: 782px) {
          width: auto;
          min-width: auto;
        }
      }
      .lastrecord {
        grid-column-start: 1;
        grid-column-end: 3;
        // grid-row-start: 2;
        // grid-row-end: 2;
        width: 100%;
        // background: red;
      }
    }
  }
}

.dashboard_devices_status_chart {
  grid-column: 3;
  // display: none;
}
.dashboard_devices_connection_chart {
  grid-column: 2;
  // display: none;
}

.dashboard_devices_connection_pie {
  display: block;
  min-height: 400px;
}
.dashboard_devices_status_pie {
  display: block;
  min-height: 400px;
}


.recharts-layer {
  &.recharts-pie-labels {
    .recharts-curve.recharts-pie-label-line {
      // display: none;
    }
    text {
      font-weight: 600;
    }
  }
}
