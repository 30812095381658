input[type="submit"],
div.button,
a.button,
button {
  // background: $color--button;
  background-color: #5573df;
  border: 0;
  border-radius: 0.375rem;
  line-height: 20px;
  color: #fff;
  // padding: 0.75rem 1rem 0.75rem 0.75rem;
  padding: 8px 12px 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media screen and (max-width: 782px) {
    padding: 0.55rem 0.75rem 0.55rem 0.55rem;
  }
  svg {
    margin-right: 0.25rem;
  }
  &.small {
    padding: .55rem .75rem .55rem 0.75rem;
    font-size: 0.875rem;
  }
  &.margin-left {
    margin-left: 0.5rem;
  }
  &.add {
    @media screen and (max-width: 782px) {
      padding: 0.55rem 0.55rem 0.55rem 0.55rem;
      .text {
        font-size: 0;
      }
      svg {
        margin-right: 0;
      }
    }
  }
}



.rs-btn-icon-circle {
  svg {
    margin-right: 0;
  }
}

$accent-color: hsl(0, 0, 50);

#details-btn-close,
#modal-btn-close {
  // position: absolute;
  width: 26px;
  height: 26px;
  transform: translate(0, 0);
  cursor: pointer;
  background: hsl(0, 0, 100);
  border-radius: 50%;
  border: none;
  outline: none;
  span {
    position: absolute;
    width: 26px;
    height: 2px;
    top: 50%;
    left: 50%;
    background: hsl(0, 0, 15);
    border-radius: 2px;
    overflow: hidden;
    transition: all 0.3s linear;
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: $accent-color;
      transition: all 0.3s linear;
    }
    &:nth-child(1) {
      animation: span-first-off 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(2) {
      animation: span-second-off 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(3) {
      animation: span-third-off 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}


#details-btn-close.on,
#modal-btn-close.on {
  &:hover span::before {
    width: 100%;
    transition: all 0.3s linear;
  }
  span {
    &:nth-child(1) {
      animation: span-first-on 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(2) {
      animation: span-second-on 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
    &:nth-child(3) {
      animation: span-third-on 0.5s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}




@keyframes span-first-on{
  0%{
    transform: translate(-50%, -300%);
  }
  30% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes span-first-off {
  0% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  30% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -300%);
  }
}


@keyframes span-second-on {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    background: $accent-color;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-150%, -50%) scale(0);
  }
}

@keyframes span-second-off {
  0% {
    transform: translate(-150%, -50%) scale(0);
  }
  25% {
    background: $accent-color;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes span-third-on {
  0% {
    transform: translate(-50%, 200%);
  }
  30% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
@keyframes span-third-off {
  0% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  30% {
    transform: translate(-50%, -50%) rotate(0deg)
  }
  100% {
    transform: translate(-50%, 200%);
  }
}


.details-button {
  cursor: pointer;
}

.show-vehicle-on-map-button {
  cursor: pointer;
}

.rs-btn-icon-with-text.rs-btn-primary {
  & > .rs-icon {
    background-color: $color--primary-matching70;
  }
  &:hover {
    & > .rs-icon {
      background-color: $color--primary-matching100;
    }
  }
  &:focus {
    background-color: $color--primary;
    & > .rs-icon {
      background-color: $color--primary-matching100;
    }
  }
}



.new-button,
.delete-button,
.track-generate-button {
  background-color: $color--primary;
  svg.rs-icon {
    background-color: $color--primary-matching70;
  }
  &:active,
  &:hover {
    background-color: $color--primary;
    svg.rs-icon {
      background-color: $color--primary-matching100;
    }
  }
}

.rs-btn-icon-with-text.rs-btn-primary:active > .rs-icon {
  background-color: $color--primary-matching100;
}

.rs-btn-icon-with-text.rs-btn {
  @media screen and (max-width: 782px) {
    font-size: 0;
  }
}

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
  @media screen and (max-width: 782px) {
    padding: 5px 10px 5px 20px;
  }
}

.track-generate-button {
  min-width: 2rem;
  margin-right: 0.25rem;
  &.rs-btn-icon.rs-btn-sm {
    padding: 7px 5px;
  }
  svg {
    fill: #fff;
    margin-right: 0;
  }
  @media screen and (max-width: 782px) {
    padding: 7px 5px;
  }
}

.rs-toggle-checked .rs-toggle-presentation:hover,
.rs-toggle-checked .rs-toggle-presentation {
    background-color: $color--primary;
}

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
  margin-right: 0;
}

.container.plusgps_styles {
  .new-button {
    background-color: $color--primary-plusgps-light;
    svg {
      background-color: $color--primary-plusgps-light-matching70;
    }
    &:hover {
      svg {
        background-color: $color--primary-plusgps-light-matching100;
      }
    }
  }
}
