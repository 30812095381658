




.vehicle.active {
  border-left: 6px solid $color--primary;
  .details-button {
    svg {
      path {
        color: $color--primary;
      }
    }
  }
}




.form_custom_message {
  display: none;
}


.content-wrapper {
  &.users,
  &.devices,
  &.organizations,
  &.notifications,
  &.notifications-rules,
  &.reports {

    .content-left {
      flex-grow: 1;
      padding: 2rem;
      &.opened {
        // min-width: 300px;
        margin-right: 33.333333%;
      }
      @media screen and (max-width: 782px) {
      margin-right: 0;
      padding: 1rem;
      }
    }

    .content-right {
      overflow: hidden;
      width: 0;
      transition: width .1s ease-in-out;
      @media screen and (max-width: 782px) {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 4rem;
        background: #fff;
        transform: translateX(100%)
      }
      &.opened {
        // min-width: 300px;
        width: 33.333333%;

        position: fixed;
        background: #fff;
        height: calc(100vh - 4rem);
        right: 0;

        @media screen and (max-width: 782px) {
          width: 100%;
          position: fixed;
          left: 4rem;
          background: #fff;
          transform: translateX(0%)
        }
      }
      & > .inner {
        border-left:1px solid $color__border-element;
        display: flex;
        height: 100%;
        // padding: 2rem;
      }
    }
    .header {
      display: flex;
      flex-direction: row;
      .left {
        flex-grow: 1;
        flex-direction: column;
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .button {
          // height: 1.25rem;
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li.device,
      li.report,
      li.organization,
      // li.notification,
      li.user {
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #ccc;
        padding: 0.5rem;
        @media screen and (max-width: 782px) {
        padding: 0rem;
        }
        display: flex;
        flex-direction: row;
        font-size: 0.875rem;

        .name, {
          min-width: 130px;
          margin-right: 0.5rem;
        }
        .details {
          font-size: 0.875rem;
          cursor: pointer;
        }

        .left,
        .right {
          display: flex;
        }
        .left {
          flex-grow: 1;
          flex-direction: column;
        }
        .top-line,
        .middle-line,
        .bottom-line {
          display: flex;
          flex-direction: row;
          @media screen and (max-width: 782px) {
            flex-direction: column;
          }
        }

        .top-line {
          align-items: flex-end;
          @media screen and (max-width: 782px) {
            align-items: flex-start;
          }
          margin-bottom: 0.25rem;
          .device_name,
          .codename,
          .device_type,
          .device_id {
            font-size: 0.875rem;
          }
          .device_name,
          .codename,
          .username,
          .licenseplate,
          .device_type {
            margin-right: 0.5rem;
          }
          .username {
            min-width: 160px;
          }

          .licenseplate {
            font-weight: 600;
          }

          .device_type,
          .device_id {
            @media screen and (max-width: 782px) {
              display: none;
            }
          }

          .type,
          .created_at,
          .date_start,
          .date_end {
            min-width: 6rem;
            margin-right: 0.5rem;
          }
          .created_at {
            min-width: 10rem;
           }
           .date_end {
             flex-grow: 1;
           }
        }
        .middle-line,
        .bottom-line {
          align-items: flex-end;
          margin-bottom: 0.25rem;
          @media screen and (max-width: 782px) {
            align-items: flex-start;
          }
          & > div {
            font-size: 0.875rem;
            margin-right: 0.5rem;
            @media screen and (max-width: 782px) {
              margin-right: 0rem;
              font-size: 0.8rem;

            }
          }
        }
        .bottom-line {
          .vehicles {
            margin-top: 0.25rem;
            span {
              border: 1px solid $color__border-input;
              padding:0.125rem;
              margin-right: 0.25rem;
            }
          }
          span.label {
            @media screen and (max-width: 782px) {
              display: block;
            }
          }
        }
        .status {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 0.875rem;
          border: 2px solid $color--primary;
          padding: 0.125rem 0.45rem;
          // margin-right: 0.25rem;
          .spinner {
            margin-right: 0.5rem;
          }
          svg {
            width: 1rem;
            height: 1rem;
            fill: $color--green;
          }
        }
        .download {
          display: flex;
          // flex-direction: row;
          // align-items: center;
          a {
            text-decoration: none;
            color: #fff;
            background: $color--primary;
            font-weight: 600;
            font-size: 0.875rem;
            padding: 0.125rem 0.45rem;
            /* padding-top: 1rem; */
            justify-content: center;
            align-items: center;
            display: flex;
            min-height: 2rem;
          }
        }
      }
      li.report {
        @media screen and (max-width: 782px) {
          flex-direction: column;
          .right {
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
  &.organizations {
    .header {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 782px) {
        flex-direction: column;
        margin-bottom: 1rem;
      }
      .left {
        flex-grow: 1;
        flex-direction: column;
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 782px) {
          flex-direction: column;
          align-items: flex-start;
          .button {
            margin-left: 0;
            margin-bottom: 0.5rem;
          }
        }
        .button {
          // height: 1.25rem;
        }
      }
    }
  }
}

.modal {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  z-index: 429;
  @media screen and (max-width: 782px) {
    position: fixed;
  }
  &.opened {
    visibility: visible;
    opacity: 1;
  }
  .add-form-element {
    margin-bottom: 1rem;
    span.label {
      display: block;
      margin-bottom: 0.25rem;
    }
  }
 .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 782px) {
      min-height: 100%;
    }
    // height: calc(100% - 4rem);
    background: #fff;
    box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.12);
    margin:2rem auto;
    border-radius: 1rem;
    .inner {
      width: calc(100%);
      padding: 2rem;
      border-bottom: 1px solid $color__border-element;
      .header {
        display: flex;
        flex-direction: row;
        .left {
          flex-grow: 1;
          h3 {
            margin-top: 0;
          }
        }
        .right {
          display: flex;
          align-items: flex-start;
        }
      }
      .content {
        form {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  &.add,
  &.edit {
    .wrapper {
      max-width: 40rem;
    }
  }
}


.container.plusgps_styles {
  .vehicle.active {
    border-left: 6px solid $color--primary-plusgps;
    .details-button {
      svg {
        path {
          color: $color--primary-plusgps;
        }
      }
    }
  }
  .content-wrapper {
    &.users,
    &.devices,
    &.organizations,
    &.reports {
      ul {
        li.device,
        li.report,
        li.organization,
        li.user {
          .status {
            border: 2px solid $color--primary-plusgps;
          }
          .download {
            a {
              background: $color--primary-plusgps;
            }
          }
        }
      }
    }
  }
}
