input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	background: transparent;
	color: #3e3e46;
	border-radius: 0px;
	font-size:1rem;
	padding:10px 10px 10px 5px;
	display:block;
	width:100%;
	border:none;
	border-bottom: 1px solid $color__border-input;
	padding: .5rem 0;
	// padding: .5rem 0 !important;
	margin-bottom: 1rem;

	line-height: 1.5;
	&:focus {
		color: #3e3e46;
		outline:0;
	}
}

textarea {
	width: 100%;
}
input[type="radio"] {
	display: none;
}

.full-width {
	width: 100%;
}

.rs-input {
	margin-bottom: 0!important;
}
.rs-input-group {
	margin-bottom: 1rem;
}
